import React from 'react';
import useTrackPageView from '~/hooks/use-track-page-view';
import useAuthentication from '~/hooks/use-authentication';
import { Wrapper, Container, Logo } from '~/components/LoginElements';
import { SignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { ChooseOrganization } from '~/components/LoginActionElements';
import theme from '~/lib/theme';

const CLERK_SIGN_IN_VARIABLES = {
  spacingUnit: '12px',
  borderRadius: '4px',
  colorPrimary: theme.darkest,
  fontWeight: { bold: 400 },
};

function Login() {
  useTrackPageView('Log In');
  const { usersOrganizations, changeOrganization } = useAuthentication();

  return (
    <Wrapper>
      <SignedOut>
        <Container>
          <Logo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
          <SignIn
            signUpUrl={`${process.env.APP_URL}/sign-up`}
            appearance={{
              variables: CLERK_SIGN_IN_VARIABLES,
            }}
          />
        </Container>
      </SignedOut>
      <SignedIn>
        <ChooseOrganization
          organizations={usersOrganizations}
          onClick={changeOrganization}
        />
      </SignedIn>
    </Wrapper>
  );
}

export default Login;
