import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import PropTypes from 'prop-types';
import ModalForm from '~/components/ModalForm';
import Input from '~/components/Input';
import { useModali } from 'modali';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import Muted from '~/components/Typography/Muted';

const StyledRow = styled.div`
  border-top: 1px solid ${props => props.theme.light};
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InfoColumn = styled.div`
  height: 30px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
`;
const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20 0px;
  width: 190px;
  justify-content: space-between;
`;
const StyledSecretName = styled.h4`
  color: ${props => props.theme.darkMedium};
  width: ${props => (props.connectorType === 'tls' ? '50px' : '200px')};
`;

function HiddenInput({ secretLabel, onChange, setShowConfirmBox, connectorType, secretKey, meta }) {
  const [localValue, setLocalValue] = useState('');
  const [updateSecretModal, toggleUpdateSecretModal] = useModali({
    animated: true,
    title: `Update ${secretLabel}`,
  });

  const isTLS = connectorType === 'tls';
  const isDpsKeyPass = connectorType === 'microsoft_azure_dps' && secretKey === 'keyPass';

  function showSecretModal(e) {
    e.preventDefault();
    toggleUpdateSecretModal();
  }

  function onModalSubmit() {
    onChange(localValue, secretLabel);
    toggleUpdateSecretModal();
    setShowConfirmBox(true);
  }

  function deleteSecretValue() {
    setLocalValue('');
    onChange(localValue, secretLabel);
    setShowConfirmBox(true);
  }

  function parseDate(date) {
    return format(parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()), 'yyyy-MM-dd HH:mm');
  }

  return (
    <>
      <StyledRow>
        <InfoColumn>
          <StyledSecretName connectorType={connectorType}>{secretLabel}</StyledSecretName>
          <Muted>
            {meta?.isSet && meta?.lastUpdatedAt
              ? `Last updated: ${parseDate(meta.lastUpdatedAt)}`
              : !meta?.isSet && meta?.lastUpdatedAt
              ? `Deleted: ${parseDate(meta.lastUpdatedAt)}`
              : ''}
          </Muted>
        </InfoColumn>
        <ButtonsColumn>
          <Button onClick={showSecretModal}>Edit</Button>
          {(isTLS || isDpsKeyPass) && meta?.isSet && (
            <Button
              variant='danger'
              onClick={deleteSecretValue}
            >
              Delete
            </Button>
          )}
        </ButtonsColumn>
      </StyledRow>

      <ModalForm
        modalOpts={updateSecretModal}
        onClickSubmit={onModalSubmit}
        submitText='Update'
        onClickCancel={toggleUpdateSecretModal}
        submitDisabled={localValue === ''}
      >
        <Input
          type='textarea'
          label={`Update ${secretLabel}`}
          placeholder='Input text'
          onChange={e => setLocalValue(e.target.value)}
        />
      </ModalForm>
    </>
  );
}
export default HiddenInput;

HiddenInput.propTypes = {
  secretLabel: PropTypes.string,
  onChange: PropTypes.func,
  setShowConfirmBox: PropTypes.func,
  meta: PropTypes.object,
  connectorType: PropTypes.string,
  secretKey: PropTypes.string,
};
