import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { HeartPulse } from '@styled-icons/remix-fill/HeartPulse';
import { Map2 as LiveMap } from '@styled-icons/remix-fill/Map2';
import { TrafficLight as TrafficAnalyzer } from '@styled-icons/remix-fill/TrafficLight';
import useOrganizations from '~/hooks/use-organizations';
import useAuthentication from '~/hooks/use-authentication';
import useClipboard from '~/hooks/use-clipboard';
import MenuHoverDropdown from '~/components/MenuHoverDropdown';
import MenuLink from '~/components/MenuLink';
import StyledNavLink from '~/components/NavLink';
import Button from '~/components/Button';
import { Card } from '~/components/Card';
import HealthStatus from '~/components/HealthStatus';
import useStatuspage from '~/hooks/use-statuspage';
import Input from '~/components/Input';
import { transparentize } from 'polished';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';
import InternalLink from '~/components/InternalLink';
import { useAuth } from '@clerk/clerk-react';

const AdminNavLink = styled(StyledNavLink)`
  color: white !important;
  border-bottom: none !important;
`;

const NaveLogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavLogo = styled.img`
  height: 25px;
  margin: 17px 17px 17px 0;
`;

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.font.primary};

  &:visited {
    color: ${props => props.theme.font.primary};
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: ${props => (props.danger ? props.theme.bgColor.danger : props.theme.bgColor.secondary)};
  background-image: url(${require('~/assets/graphics/header-logo-cutoff.png')});
  background-position: 100%, 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1728px;
  margin: 0px auto;
  @media (max-width: calc(1728px + 6vw)) {
    padding: 0 3vw;
    width: 100%;
  }
`;

const BottomBar = styled.div`
  display: flex;
  align-items: flex-end;
  height: 60px;
  background-color: ${props => props.theme.bgColor.main};
  width: 1728px;
  margin: 0px auto;
  @media (max-width: calc(1728px + 6vw)) {
    padding: 0 3vw;
    width: 100%;
  }
`;

const BarContainer = styled.div`
  width: 100%;
  ${props =>
    props.isTop &&
    css`
      background-color: ${props => (props.danger ? props.theme.bgColor.danger : props.theme.bgColor.secondary)};
    `}
  ${props =>
    props.isBottom &&
    css`
      background-color: ${props => props.theme.bgColor.main};
    `}
`;

const RightSection = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Input)`
  font-size: 1rem;
  border-bottom: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300;
`;

const StyledActivityIcon = styled(HeartPulse)`
  color: white;
`;
const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;
const DropdownContainer = styled.div`
  z-index: ${props => props.theme.zIndex.navbar + 1};
  position: relative;
  display: inline-block;
`;
const DropdownTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.font.secondary};
  cursor: pointer;
  align-items: center;
  height: 30px;
`;
const DropdownWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;

  ${DropdownContainer}:hover & {
    display: block;
  }
`;
const DropdownContent = styled(Card)`
  padding: 10px 0;
  color: ${props => props.theme.font.primary};
  min-width: 160px;
`;
const DropdownItem = styled.div`
  padding: 8px 18px;
  &:hover {
    background: ${props => transparentize(0.4, props.theme.bgColor.muted)};
  }
`;
const DropdownBreaker = styled.div`
  height: 1px;
  background-color: ${props => props.theme.bgColor.muted};
  width: 100%;
  margin: 8px 0 8px 0;
`;
const DropdownOrgNameWrapper = styled.div`
  padding: 0 20px;
`;

const Nav = styled.nav`
  z-index: ${props => props.theme.zIndex.navbar};
`;

function Navigation() {
  const clipboard = useClipboard();
  const {
    email,
    isOrgMember,
    isOwner,
    isDemiGod,
    isGod,
    assumeOrganizationId,
    assumedOrganizationId,
    isViewingAsAssumedOrganization,
    toggleIsViewingAsAssumedOrganization,
    usersOrganizations,
    usersCurrentOrganizationId,
    changeOrganization,
  } = useAuthentication();
  const { getToken: clerkGetToken } = useAuth();
  const { overallStatus, networkStatus, webhooksStatus, apiStatus, appStatus } = useStatuspage();
  const organizations = useOrganizations();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const assumedOrganizationActivityUrl =
    assumedOrganizationId &&
    !organizations.loading &&
    organizations.data.find(({ id }) => id === assumedOrganizationId)?.activityUrl;
  const organizationsItems = organizations.loading
    ? [{ label: 'Loading', value: '' }]
    : organizations.data
    ? [
        {
          label: assumedOrganizationId !== null ? 'Cancel' : 'Choose organization',
          value: '',
        },
        ...organizations.data.map(a => ({
          label: `${a.id} - ${a.entity}`,
          value: a.id,
        })),
      ]
    : [];

  async function copyClerkToken() {
    const clerkToken = await clerkGetToken({ template: process.env.CLERK_LONGER_LASTING_TOKEN_TEMPLATE });
    clipboard.write(clerkToken);
  }

  return (
    <Nav>
      <BarContainer
        isTop
        danger={assumedOrganizationId !== null}
      >
        <TopBar danger={assumedOrganizationId !== null}>
          <NaveLogoWrapper>
            <InternalLink url='/'>
              <NavLogo src={require('~/assets/graphics/onomondo-logo-type-negative.svg')} />
            </InternalLink>
          </NaveLogoWrapper>
          <RightSideWrapper>
            {(isDemiGod || isGod) && (
              <Button
                variant='danger'
                onClick={toggleIsViewingAsAssumedOrganization}
              >
                {!isViewingAsAssumedOrganization
                  ? 'View as assumed organization'
                  : 'Disable "View as assumed organization"'}
              </Button>
            )}
            {(isDevelopment || isGod) && clipboard.canWrite && (
              <Button
                variant='danger'
                onClick={() => copyClerkToken()}
              >
                Copy token
              </Button>
            )}
            {assumedOrganizationActivityUrl && (isDemiGod || isGod) && (
              <a
                href={assumedOrganizationActivityUrl}
                target='_blank'
                rel='noreferrer'
              >
                <StyledActivityIcon size={20} />
              </a>
            )}
            {(isDemiGod || isGod) && (
              <>
                <StyledSelect
                  type='select'
                  noMargin
                  canSearch
                  width='300px'
                  items={organizationsItems}
                  value={assumedOrganizationId || ''}
                  onChange={assumeOrganizationId}
                  placeholder='Choose organization'
                  optionsWrapperProps={{
                    maxHeight: '300px',
                  }}
                />
                <div>
                  <AdminNavLink to='/admin'>Admin</AdminNavLink>
                </div>
              </>
            )}
            <DropdownContainer>
              <DropdownTrigger>
                {email}
                <Icon
                  name='carret-down'
                  width={27}
                  height={27}
                  color={theme.lightest}
                />
              </DropdownTrigger>

              <DropdownWrapper>
                <DropdownContent>
                  <DropdownOrgNameWrapper>
                    {usersOrganizations.length > 1 ? (
                      <StyledSelect
                        type='select'
                        noMargin
                        items={usersOrganizations.map(o => ({
                          label: o?.name,
                          value: o?.id,
                        }))}
                        value={usersCurrentOrganizationId}
                        onChange={changeOrganization}
                      />
                    ) : (
                      <strong>{usersOrganizations?.[0].name}</strong>
                    )}
                  </DropdownOrgNameWrapper>
                  <DropdownBreaker />
                  <StyledLink to='/settings'>
                    <DropdownItem>Settings</DropdownItem>
                  </StyledLink>
                  {(isOwner || isDemiGod || isGod) && (
                    <StyledLink to='/invoices'>
                      <DropdownItem>Invoice Overview</DropdownItem>
                    </StyledLink>
                  )}
                  <a
                    href='https://help.onomondo.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <DropdownItem>Knowledge Base</DropdownItem>
                  </a>
                  <a
                    href='https://docs.onomondo.com'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <DropdownItem>API Docs</DropdownItem>
                  </a>
                  <DropdownBreaker />
                  <StyledLink to='/logout'>
                    <DropdownItem>Log Out</DropdownItem>
                  </StyledLink>
                </DropdownContent>
              </DropdownWrapper>
            </DropdownContainer>
          </RightSideWrapper>
        </TopBar>
      </BarContainer>
      <BarContainer isBottom>
        <BottomBar>
          <MenuLink
            to='/dashboard'
            label='Dashboard'
            icon={
              <Icon
                name='dashboard'
                width={20}
                height={20}
              />
            }
          />
          <MenuLink
            to='/sims'
            label='SIMs'
            icon={
              <Icon
                name='sim-card'
                width={20}
                height={20}
              />
            }
          />
          <MenuLink
            to='/usage'
            label='Usage'
            icon={
              <Icon
                name='bar-chart-2'
                width={20}
                height={20}
              />
            }
          />
          <MenuLink
            to='/network-lists'
            label='Network Lists'
            icon={
              <Icon
                name='list'
                width={20}
                height={20}
              />
            }
          />
          {!isOrgMember && (
            <>
              <MenuLink
                to='/users'
                label='Users'
                icon={
                  <Icon
                    name='user'
                    width={20}
                    height={20}
                  />
                }
              />
              <MenuLink
                to='/tags'
                label='Tags'
                icon={
                  <Icon
                    name='tag'
                    width={20}
                    height={20}
                  />
                }
              />
            </>
          )}
          <MenuHoverDropdown label='More'>
            {!isOrgMember && (
              <>
                <MenuLink
                  isVertical
                  to='/error-log'
                  label='Error Log'
                  icon={
                    <Icon
                      name='alert-circle'
                      width={20}
                      height={20}
                    />
                  }
                />
                <MenuLink
                  isVertical
                  featureName='management.webhooks'
                  to='/webhooks'
                  label='Webhooks'
                  icon={
                    <Icon
                      name='webhooks'
                      width={20}
                      height={20}
                    />
                  }
                />
              </>
            )}
            <MenuLink
              isVertical
              featureName='management.apiKeys'
              to='/api-keys'
              label='API Keys'
              icon={
                <Icon
                  name='shield-with-key'
                  width={20}
                  height={20}
                />
              }
            />
            <MenuLink
              isVertical
              featureName='connectors'
              to='/connectors'
              label='Connectors'
              icon={
                <Icon
                  name='plug'
                  width={20}
                  height={20}
                />
              }
            />
            {(isOwner || isGod) && (
              <MenuLink
                isVertical
                featureName='management.auditLogs'
                to='/audit-logs'
                label='Audit Log'
                icon={
                  <Icon
                    name='audit-logs'
                    width={20}
                    height={20}
                  />
                }
              />
            )}
          </MenuHoverDropdown>
          {isGod && (
            <MenuHoverDropdown label='Beta'>
              <MenuLink
                isVertical
                to='/live-map'
                label='Live Map (alpha)'
                icon={<LiveMap size={20} />}
              />
              <MenuLink
                isVertical
                to='/traffic-analyzer'
                label='Traffic Analyzer (alpha)'
                icon={<TrafficAnalyzer size={20} />}
              />
            </MenuHoverDropdown>
          )}

          <RightSection>
            <HealthStatus
              overall={overallStatus}
              network={networkStatus}
              webhooks={webhooksStatus}
              api={apiStatus}
              app={appStatus}
            >
              Onomondo status
            </HealthStatus>
          </RightSection>
        </BottomBar>
      </BarContainer>
    </Nav>
  );
}

export default Navigation;
