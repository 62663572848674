import { createGlobalStyle, css } from 'styled-components';
import 'modali/dist/modali.css';
import theme from '~/lib/theme';

// clerk overrides general css styles
const clerkFontFamilyCss = css`
  font-family: 'Neue Montreal';
  font-style: normal;
`;

const clerkBaseFontCss = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
`;

const clerkThinFontCss = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
`;

const clerkSmallFontCss = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.48px;
`;

const clerkBigFontCss = css`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
`;

const clerkErrorCss = css`
  text-align: left;
  color: rgba(205, 0, 8, 0.76);
  ${clerkSmallFontCss}
`;

const clerkBlackButtonBaseCss = css`
  border-radius: 9999px;
  background: #1c2024;
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const clerkFactorStepsBaseCss = css`
  gap: 40px;
  .cl-identityPreview {
    justify-content: unset;
    p {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
  }
  .cl-form {
    gap: 0px;
  }
  .cl-formHeaderTitle {
    text-align: left;
    color: #1c2024;
    ${clerkBaseFontCss}
    margin-top: 9px;
  }
  .cl-formHeaderSubtitle {
    text-align: left;
    color: #60646c;
    ${clerkThinFontCss}
  }
  .cl-otpCodeFieldInputs {
    width: 100%;
    justify-content: space-between;
  }
  .cl-otpCodeFieldInput {
    width: 48px;
    max-width: unset;
    color: #1c2024;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  .cl-otpCodeFieldErrorText {
    color: rgba(205, 0, 8, 0.76);
    ${clerkSmallFontCss}
    svg {
      display: none;
    }
  }
  .cl-internal-1s2rqwh {
    width: 100%;
  }
  .cl-formResendCodeLink {
    width: 100%;
    text-align: center;
    color: #60646c;
    ${clerkBaseFontCss}
    margin: 0px;
  }
`;

const clerkBackgroundImageCss = css`
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
`;

// https://gist.github.com/thisisadityarao/cc86efbdd916c6031f8ca3601c107d71
const GlobalStyles = createGlobalStyle`
  /****** IMPORTED FONTS ******/
  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Light.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Light.woff')}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Italic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Italic.woff')}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Medium.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Medium.woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Regular.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Regular.woff')}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff')}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff')}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Bold.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Bold.woff')}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff')}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${require('~/assets/fonts/Roboto-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }

  /* ==========================================================================
    GLOBAL STYLES
    ========================================================================== */

  /* Box Sizing
    ========================================================================== */
  html {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: 'NeueMontreal', sans-serif;
    font-weight: 300;
  }

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    /* background-color: ${props => props.theme.bgColor.muted}; */
    background-color: ${props => props.theme.gray};
  }

  #root {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* PARAGRAPHS
    ========================================================================== */

  p {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* HEADINGS
    ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Neue Montreal';
    color: ${props => props.theme.darker};
    margin: 0;
  }

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    color: ${props => props.theme.dark};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  h3 {
    color: ${props => props.theme.copperDarkest};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.theme.darker};
  }

  /* TABLES
    ========================================================================== */

  th {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.theme.dark};
    vertical-align: bottom;
  }

  td {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* LINKS
    ========================================================================== */

  a {
    text-decoration: none;
    color: ${props => props.theme.link.color};
  }
  a[data-underline] {
    text-decoration: underline;
  }

  /* A better looking default horizontal rule
    ========================================================================== */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  ========================================================================== */

  img,
  svg {
    vertical-align: middle;
  }

  /* IMAGES
    ========================================================================== */

  img {
    height: auto;
    width: auto;
    /* max-width allows image to be responsive. */
    max-width: 100%;
  }

  /* LISTS
    ========================================================================== */
  ul {
    padding-left: 20px;
  }

  /* dont add padding from modali when it's open */
  @media (min-width: 1025px) {
    .modali-open {
      padding-right: 0 !important;
      overflow: unset !important;
    }
  }

  /* make modal body from modali scrollable */
  .modali-body-style {
    max-height: 70vh;
    overflow-y: auto;
  }

  .modali-overlay {
    z-index: ${theme.zIndex.modal};
  }
  .modali-wrapper {
    z-index: ${theme.zIndex.modal + 1};
  }

  /* stop modali from using both in animation-fill-mode */
  .modali-animated {
    animation-fill-mode: none !important;
  }

  /* clerk overrides */
  .cl-rootBox {
    ${clerkFontFamilyCss}
  }
  .cl-card {
    min-width: 344px;
    box-shadow: none;
    margin: 0px 17px;
    padding: 28.5px 2px 36px;
    @media (max-width: 1025px) {
      min-width: 0px;
    }
  }

  .cl-header {
    margin: 20px 0;
    gap: 8px;
  }

  .cl-headerTitle {
    color: #1C2024;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }

  .cl-headerSubtitle {
    text-align: center;
    color: #60646C;
    ${clerkThinFontCss}
  }

  .cl-headerBackRow {
    justify-content: center;
    a {
      gap: 8px;
      svg {
        height: 16px;
        width: 16px;
        color: #60646C;
      }
    }
  }

  .cl-headerBackLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-alternativeMethods {
    gap: 12px;
  }

  .cl-socialButtons {
    gap: 12px;
  }

  .cl-alternativeMethodsBlockButton {
    ${clerkBlackButtonBaseCss}
    :hover {
      border-radius: 9999px;
      background: #1C2024;
      svg {
        width: 0px;
        height: 0px;
        stroke: #1C2024;
      }
    }
    svg {
      width: 0px;
      height: 0px;
    }
  }

  .cl-alternativeMethodsBlockButtonText {
    color: #FCFCFD;
    ${clerkBigFontCss}
  }

  .cl-internal-egu47 {
    justify-content: center;
  }

  .cl-identityPreview {
    height: 32px;
    max-width: unset;
    justify-content: space-between;
    gap: 8px;
    .cl-identityPreviewText {
      ${clerkFontFamilyCss}
      color: rgba(0, 7, 19, 0.62);
      ${clerkBaseFontCss}
    }
    div {
      margin-right: 0px;
    }
    p {
      margin-right: 0px;
    }
    button {
      margin-left: auto;
      padding: 0px;
      min-height: unset;
      svg {
        display: none;
      }
      background-image: url(${require('~/assets/graphics/edit-05.svg')});
      ${clerkBackgroundImageCss}
    }
  }

  .cl-signIn-forgotPasswordMethods {
    .cl-identityPreview {
        justify-content: unset;
    }
    .cl-internal-uqm3h2 {
      .cl-alternativeMethodsBlockButton {
        border-radius: 9999px;
        border: 1px solid rgba(1, 6, 47, 0.17);
        justify-content: center;
        gap: 8px;
        background: #ffffff;
        div {
          width: unset;
        }
        :hover {
          background: rgba(0, 0, 0, 0.04);
          svg {
            width: 0px;
            height: 0px;
            stroke: #1C2024;
          }
        }
      }
      .cl-alternativeMethodsBlockButtonText {
        color: #60646C;
        ${clerkBaseFontCss}
      }
    }
  }

  .cl-signIn-totp2Fa {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 0px;
    }
    svg {
      background-image: url(${require('~/assets/graphics/shield-tick.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-signIn-emailLinkVerify {
    ${clerkFactorStepsBaseCss}
    .cl-formHeaderTitle {
      text-align: center;
    }
    .cl-formHeaderSubtitle {
      text-align: center;
    }
    .cl-formResendCodeLink {
      margin-top: 16px;
    }
  }

  .cl-signIn-resetPassword {
    ${clerkFactorStepsBaseCss}
    .cl-otpCodeField {
      width: 100%;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 40px;
    }
    .cl-formResendCodeLink {
      width: 100%;
      text-align: center;
      color: #60646C;
      ${clerkBaseFontCss}
      margin: 0px;
    }
  }

  .cl-signIn-havingTrouble {
    .cl-main {
      button {
        ${clerkBlackButtonBaseCss}
        div {
          span {
            color: #FCFCFD;
            ${clerkBigFontCss}
          }
        }
        svg {
          width: 0px;
          heigth: 0px;
          stroke: #1C2024;
        }
      }
    }
  }

  .cl-avatarBox {
    background-image: url(${require('~/assets/graphics/user-03.svg')});
    ${clerkBackgroundImageCss}
    background-color: unset;
    img {
      display:none;
    }
  }

  .cl-socialButtonsBlockButtonText {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-socialButtonsBlockButton {
    border-radius: 9999px;
    border: 1px solid rgba(1, 6, 47, 0.17);
    justify-content: center;
    gap: 8px;
    div {
      width: unset;
    }
  }

  .cl-main {
    gap: 40px;
    p {
      color: #60646C;
      text-align: center;
      ${clerkSmallFontCss}
    }
  }

  .cl-formButtonPrimary {
    ${clerkBlackButtonBaseCss}
    margin-top: 24px;
    color: #FCFCFD;
    ${clerkBigFontCss}
    text-transform: none;
  }

  .cl-form {
    gap: 16px;
  }

  .cl-formField {
    gap: 4px;
    display: flex;
  }

  .cl-formFieldLabel {
    color: #1C2024;
    ${clerkBaseFontCss}
  }

  .cl-formFieldInput {
    color: #1C2024;
    ${clerkThinFontCss}
  }

  .cl-formFieldAction {
    color: ${theme.copper};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.48px;
  }

  .cl-formFieldErrorText {
    svg {
      display:none;
    }
  }

  #error-password {
    ${clerkErrorCss}
  }

  #error-confirmPassword {
    ${clerkErrorCss}
  }

  #error-identifier {
    ${clerkErrorCss}
  }

  .cl-alert {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #F9C6C6;
    background: #FFF7F7;
    align-items: center;

    .cl-alertText {
      color: rgba(187, 0, 7, 0.84);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
    svg {
      margin-right: 0px;
      background-image: url(${require('~/assets/graphics/info-circle-red.svg')});
      ${clerkBackgroundImageCss}
      path {
        display: none;
      }
    }
  }

  .cl-footer {
    justify-content: center;
  }

  .cl-footerAction__signIn {
    margin-top: 24px;
    .cl-footerActionLink {
      color: ${theme.copper};
      ${clerkBaseFontCss}
    }
  }

  .cl-footerActionText {
    color: #1C2024;
    ${clerkThinFontCss}
  }

  .cl-footerActionLink {
    color: #60646C;
    ${clerkBaseFontCss}
  }

  .cl-main ~ div[class^='cl-internal'] {
    display: none;
  }
`;

export default GlobalStyles;
